import { AssetsService, CameraMovementTypeEnums, CameraService, Cannon, createArrowHelper, createDefaultCube, MathService, MathUtils, PhysicsService, RenderService, Three, TimeService, UtilsService } from "three-default-cube";
import { PlayerStatusService } from "../services/player-status-service";

export const WalkingEnums = {
  modeDefault: 'default',
  modeAim: 'aim',
};

const DEBUG_CHARACTER_SPINE_MOTION = false;

export class WalkingGameObject {
  mode = WalkingEnums.modeDefault;
  keysMap = {
    forward: 'w',
    back: 's',
    left: 'a',
    right: 'd',
    jump: ' ',
    run: 'shift'
  };
  isMoving = 0.0;
  isRunning = 0.0;
  isJumping = 0.0;

  currentJumpCooldown = 1.0;
  currentRunCooldown = 0.0;

  speed = 0.0;
  direction = new Three.Vector3(0.0, 0.0, 0.0);
  velocity = new Three.Vector3(0.0, 0.0, 0.0);
  walkingSpeed = 2.0;
  runningSpeed = 3.0;
  crawlSpeed = 1.5;
  jumpHeight = 15.0;
  jumpCooldown = 0.1;
  runCooldown = 100.0;

  spineMaxAngle = Math.PI / 2.0;

  constructor(props) {
    this.makeWalking(props);
    this.makeJumping(props);
  }

  makeWalking({ target, spine }) {
    const scene = RenderService.getScene();
    const body = PhysicsService.getBodyFromObject(target);

    if (!body) {
      console.info('WalkingGameObject', 'makeWalking', 'walking controller requires PhysicsWrapper as a target');

      return;
    }

    PhysicsService.minGroundDistance = 0.5;
    PhysicsService.physicsWorld.gravity.y = -20.0;

    // NOTE PointerLock controls

    const renderer = RenderService.getRenderer();
    renderer.domElement.requestPointerLock();

    // NOTE End of PointerLock controls

    const listener = TimeService.registerFrameListener(() => {
      const isGrounded = PhysicsService.isGrounded(body);
      const isInFocusMode = this.mode === WalkingEnums.modeAim;
      const camera = RenderService.getNativeCamera();
      const lookAt = MathService.getVec3();

      const { inputs } = body.userData;
      let keyUp = inputs.keys[this.keysMap.forward];
      const keyDown = inputs.keys[this.keysMap.back];
      const keyLeft = inputs.keys[this.keysMap.left];
      const keyRight = inputs.keys[this.keysMap.right];
      let keyRun = inputs.keys[this.keysMap.run];

      const mock = UtilsService.getEmpty();
      scene.add(mock);

      target.getWorldPosition(mock.position);
      target.getWorldQuaternion(mock.quaternion);

      camera.getWorldDirection(this.direction);
      this.direction.y = 0.0;

      lookAt.copy(this.direction);

      if (!keyUp && !keyDown) {
        this.direction.set(0.0, 0.0, 0.0);
      }

      if ((keyLeft || keyRight) && !keyDown) {
        keyUp = true;
      }

      if (keyLeft || keyRight) {
        const right = MathService.getVec3();
        camera.getWorldDirection(right);

        const up = MathService.getVec3().copy(PhysicsService.physicsWorld.gravity).normalize();

        right.applyAxisAngle(up, (keyLeft ? -1.0 : 1.0) * (keyDown ? -1.0 : 1.0) * Math.PI / 2.0);

        this.direction.add(right).normalize();

        MathService.releaseVec3(up);
        MathService.releaseVec3(right);
      }

      this.direction.y = 0.0;
      this.direction.normalize();

      if (keyDown && !isInFocusMode) {
        this.direction.negate();
      }

      lookAt.copy(this.direction);

      const targetPointLookAt = MathService.getVec3();
      targetPointLookAt.copy(mock.position).add(lookAt);
      
      mock.lookAt(targetPointLookAt);

      MathService.releaseVec3(lookAt);

      let adjustSpineDirection = false;

      if (isInFocusMode) {
        const spinePosition = MathService.getVec3();
        const spineTarget = MathService.getVec3();

        spine.getWorldPosition(spinePosition);
        camera.getWorldDirection(spineTarget)
          .multiplyScalar(15.0)
          .add(spinePosition);
        spine.lookAt(spineTarget);
        spine.rotateY(-0.8);
        spine.rotateX(0.1);

        const spineDirection = MathService.getVec3();
        const characterDirection = MathService.getVec3();

        camera.getWorldDirection(spineDirection);
        target.getWorldDirection(characterDirection);

        spineDirection.y = 0.0;
        characterDirection.y = 0.0;

        adjustSpineDirection = spineDirection.angleTo(characterDirection) > this.spineMaxAngle;

        if (DEBUG_CHARACTER_SPINE_MOTION) {
          createArrowHelper(scene, 'spine-dir', spineDirection, target.position, 0xff0000);
          createArrowHelper(scene, 'char-dir', characterDirection, target.position, 0x00ff00);
        }

        MathService.releaseVec3(spinePosition);
        MathService.releaseVec3(spineTarget);
        MathService.releaseVec3(spineDirection);
        MathService.releaseVec3(characterDirection);
      }

      if (this.direction.length() > 0.0) {
        body.quaternion.slerp(mock.quaternion, 0.1, body.quaternion);
      } else if (adjustSpineDirection) {
        targetPointLookAt.set(0.0, 0.0, 0.0);
        camera.getWorldDirection(targetPointLookAt);
        targetPointLookAt.y = 0.0;
        targetPointLookAt.add(mock.position);
        
        mock.lookAt(targetPointLookAt);

        body.quaternion.slerp(mock.quaternion, 0.05, body.quaternion);
      }

      if (keyRun && !isInFocusMode && isGrounded) {
        if (this.currentRunCooldown) {
          keyRun = false;
        } else if (PlayerStatusService.energy > 0.0 && this.direction.length() > 0.0) {
          PlayerStatusService.energy -= 1.0;
        } else if (PlayerStatusService.energy === 0.0) {
          keyRun = false;

          PlayerStatusService.energy = 0.0;
          this.currentRunCooldown = this.runCooldown;
        }
      } else if (PlayerStatusService.energy < PlayerStatusService.maxEnergy) {
        PlayerStatusService.energy += this.isMoving ? 1.0 : 2.0;
      }

      if (!keyRun && this.currentRunCooldown) {
        this.currentRunCooldown -= 1.0;

        if (PlayerStatusService.energy < PlayerStatusService.maxEnergy) {
          PlayerStatusService.energy += this.isMoving ? 1.0 : 2.0;
        }
      }

      let maxSpeed = MathUtils.lerp(this.walkingSpeed, this.runningSpeed, this.isRunning);

      if (keyRun && (keyUp || keyLeft || keyRight || keyDown) && !isInFocusMode && isGrounded) {
        this.isRunning = MathUtils.lerp(this.isRunning, 1.0, 0.1);
      } else {
        this.isRunning = MathUtils.lerp(this.isRunning, 0.0, 0.2);
      }

      if (keyUp || (keyDown && !isInFocusMode)) {
        this.speed = MathUtils.lerp(this.speed, maxSpeed, 0.2);
      } else if (keyDown) {
        this.speed = MathUtils.lerp(this.speed, -this.crawlSpeed, 0.2);
      }

      if (!keyUp && !keyDown && !keyRight && !keyLeft && isGrounded) {
        this.speed = MathUtils.lerp(this.speed, 0.0, 0.2);
      }

      target.quaternion.copy(body.quaternion);
      
      const force = MathService.getVec3(0.0, 0.0, 0.0);

      const targetPoint = MathService.getVec3()
        .copy(mock.position)
        .add(this.direction);
      
      mock.lookAt(targetPoint);

      if (this.direction.length() > 0.0) {
        mock.getWorldDirection(force);
      }
      
      force.multiplyScalar(this.speed * 0.05);

      if (isGrounded) {
        this.velocity.copy(force);

        body.material.friction = 0.1;
      } else {
        body.position.x += force.x * 0.2;
        body.position.z += force.z * 0.2;

        body.material.friction = 0.0;
      }

      body.position.x += this.velocity.x;
      body.position.z += this.velocity.z;
      body.velocity.x = body.velocity.z = 0.0;

      this.isMoving = Math.abs(this.speed) * (1.0 / maxSpeed);

      MathService.releaseVec3(force);
      MathService.releaseVec3(targetPoint);
      MathService.releaseVec3(targetPointLookAt);
      UtilsService.releaseEmpty(mock);
    });

    AssetsService.registerDisposeCallback(target, () => {
      TimeService.disposeFrameListener(listener);
    });
  }

  makeJumping({ target }) {
    const body = PhysicsService.getBodyFromObject(target);

    if (!body) {
      console.info('WalkingGameObject', 'makeJumping', 'jumping controller requires PhysicsWrapper as a target');

      return;
    }

    const listener = TimeService.registerFrameListener(() => {
      const isGrounded = PhysicsService.isGrounded(body);

      const { inputs } = body.userData;
      const keyJump = inputs.keys[this.keysMap.jump];

      if (!isGrounded) {
        this.currentJumpCooldown = MathUtils.lerp(this.currentJumpCooldown, 1.0, 0.005);
      } else {
        this.currentJumpCooldown = MathUtils.lerp(this.currentJumpCooldown, -0.1, 0.1);
      }

      if (this.currentJumpCooldown <= 0.0 && keyJump) {
        body.velocity.y = this.jumpHeight;
        this.currentJumpCooldown = this.jumpCooldown;
      }

      this.isJumping = Math.max(0.0, this.currentJumpCooldown);
    });

    AssetsService.registerDisposeCallback(target, () => {
      TimeService.disposeFrameListener(listener);
    });
  }
}