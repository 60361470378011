import {
  RenderService,
  SystemService,
  DebugFlags,
  DebugService,
  GameInfoService,
  Three,
  DQ,
  UiService
} from 'three-default-cube';
import { SandboxView } from './game-views/sandbox-view';
import { gameServers } from './game-servers';
import packageJson from '../package.json';
import { inject } from '@vercel/analytics';

inject();

console.info('client', 'packageVersion', packageJson.version);

DebugService.on(DebugFlags.DEBUG_ENABLE);
// DebugService.on(DebugFlags.DEBUG_LIVE);
// DebugService.on(DebugFlags.DEBUG_LOG_ASSETS);
// DebugService.on(DebugFlags.DEBUG_LOG_MEMORY);
// DebugService.on(DebugFlags.DEBUG_LOG_POOLS);
// DebugService.on(DebugFlags.DEBUG_STORAGE);
// DebugService.on(DebugFlags.DEBUG_TIME_LISTENERS);
// DebugService.on(DebugFlags.DEBUG_NETWORK);
// DebugService.on(DebugFlags.DEBUG_PHYSICS);

if (window.location.search.includes('debug')) {
  DebugService.on(DebugFlags.DEBUG_PHYSICS);
}

UiService.tween = 1.0;

GameInfoService
  .system(60, window.devicePixelRatio, true, true, 0x000000, true)
  .camera(50, 0.1, 1000.0)
  .shadows(DQ.ShadowsAllObjects, 256, 4096, 1, Three.PCFSoftShadowMap)
  // .network('ws://localhost:2567');

// if (!window.location.search.includes('single')) {
  // GameInfoService.network(`wss://${gameServers[1].ipAddress}`);
// }

SystemService.init();
SystemService.onReady(async () => {
  const rootElement = document.querySelector('#root');

  RenderService.init({ domElement: rootElement });

  RenderService.renderView(new SandboxView());

  RenderService.run();
});
