import {
  ViewClass,
  Preloader,
  AssetsService,
  RenderService,
  SceneService,
  Three,
  CameraService,
  InputService,
  AudioService,
  NetworkService,
  SpawnService,
  UiService,
  ParserService,
  convertMaterialType,
  TimeService,
  MathService,
  MathUtils,
} from 'three-default-cube';
import SandboxModel from '../assets/models/maps/sandbox.glb';
import UiModel from '../assets/models/ui/ui.glb';
import EnvMapHdri from '../assets/textures/env-map-day-4k.hdr';
import AmbientAudio from '../assets/audio/ambient-short.mp3';
import { CharacterGameObject } from '../game-objects/character-game-object';
import { PlayerStatusService } from '../services/player-status-service';

export class SandboxView extends ViewClass {
  onCreate() {
    const scene = RenderService.getScene();

    const preloaderObject = new Preloader({
      requireAssets: [
        AssetsService.getHDRI(EnvMapHdri),
        AssetsService.getModel(SandboxModel),
        AssetsService.getModel(UiModel),
        AssetsService.getAudio(AmbientAudio)
      ],
      onComplete: ([
        envMapHdri,
        viewModel,
        uiModel,
        ambientAudio
      ]) => {
        SceneService.setEnvironment(envMapHdri);
        SceneService.setBackground(envMapHdri, false);
        scene.fog = new Three.FogExp2(0x5c5c59, 0.003);

        AudioService.setAudioVolume(ambientAudio, 0.1);
        AudioService.playAudio(0, ambientAudio, true);

        UiService.registerUiElement(uiModel);

        uiModel.traverse(child => {
          if (child.material) {
            const materialOverride = convertMaterialType(child.material, 'basic');

            AssetsService.disposeAsset(child.material);
            child.material = materialOverride;
          }
        });

        ParserService.parseModel({
          target: uiModel,
          gameObjects: {
            crosshair: (object) => {
              const arms = [];
              const armMaterial = new Three.MeshBasicMaterial({
                color: 0xffffff,
                opacity: 1.0,
                transparent: true
              });

              object.traverse(child => {
                if (child.userData.arm) {
                  arms.push({
                    arm: child,
                    position: child.position.clone(),
                    direction: child.position.clone().normalize()
                  });

                  AssetsService.disposeAsset(child.material);
                  child.material = armMaterial;
                }
              });

              TimeService.registerFrameListener(() => {
                const { focus } = PlayerStatusService;

                armMaterial.opacity = focus * 0.75 + 0.25;

                arms.forEach(({ arm, position, direction }) => {
                  arm.position.copy(position);
                  arm.position.add(direction.clone().multiplyScalar(1.0 - focus));
                });
              });
            },
            healthIndicator: (object) => {
              TimeService.registerFrameListener(() => {
                const { health, maxHealth } = PlayerStatusService;

                object.scale.x = MathUtils.clamp(health / maxHealth, 0.0, 1.0);
              });
            },
            energyIndicator: (object) => {
              TimeService.registerFrameListener(() => {
                const { energy, maxEnergy } = PlayerStatusService;

                object.scale.x = MathUtils.clamp(energy / maxEnergy, 0.0, 1.0);
              });
            }
          }
        });

        SpawnService.registerSpawnableGameObject('player', ({ clientId, inputs }) => {
          return new CharacterGameObject({
            characterId: clientId,
            inputs: clientId === NetworkService.client ? InputService : inputs
          });
        });

        SceneService.parseScene({
          target: viewModel,
          gameObjects: {

          },
          onCreate: () => {
            scene.add(viewModel);

            if (!NetworkService.isMultiplayer) {
              const player = SpawnService.createSpawnableGameObject('player', { clientId: null, inputs: InputService });
            } else {
              const players = {};

              NetworkService.registerServerActionListener('playerJoined', ({ clientId }) => {
                players[clientId] = {
                  inputs: {
                    keys: {}
                  }
                };

                if (!players[clientId]) {
                  return;
                }

                players[clientId].object = SpawnService.createSpawnableGameObject('player', { clientId, inputs: players[clientId].inputs });
              });

              NetworkService.registerServerActionListener('playerLeft', ({ clientId }) => {
                if (!players[clientId]) {
                  return;
                }

                AssetsService.disposeAsset(players[clientId].object);

                delete players[clientId];
              });

              NetworkService.registerServerActionListener('inputChange', ({ clientId, payload }) => {
                const { key, status } = payload;

                if (!players[clientId] || !players[clientId].inputs) {
                  return;
                }

                players[clientId].inputs.keys[key] = status;
              });

              NetworkService.registerClientHandler(() => {
                InputService.registerListener(({ key, status }) => {
                  NetworkService.send('inputChange', { key, status });
                });
              });
        
              CameraService.useStaticCamera(new Three.Vector3(-1.0, -1.0, -1.0), new Three.Vector3(0.0, 0.0, 0.0), true);
            }

            SceneService.setSun(0xffffff, 1.0);
          }
        });
      }
    });

    scene.add(preloaderObject);
  }
}