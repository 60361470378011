import {
  AssetsService,
  Three,
  CameraService,
  AnimationWrapper,
  TimeService,
  MathUtils,
  MathService,
  PhysicsWrapper,
  AudioService,
  NetworkService,
  GameObjectClass,
  convertMaterialType,
  PhysicsService,
  RenderService,
  InteractionsService,
  InputService,
  portMaterialsToMixamoMesh,
  CameraMovementTypeEnums
} from 'three-default-cube';

import MechModelMaterials from '../assets/models/actors/mech/materials.glb';
import MechModel from '../assets/models/actors/mech/base.fbx';
import MechIdleAnimation from '../assets/models/actors/mech/idle.fbx';
import MechRunningAnimation from '../assets/models/actors/mech/run.fbx';
import MechWalkingAnimation from '../assets/models/actors/mech/walk.fbx';
import MechJumpAnimation from '../assets/models/actors/mech/jump.fbx';
import MechAimAnimation from '../assets/models/actors/mech/aim-1.fbx';

import GunModel from '../assets/models/items/gun-1.glb';

import MechEngineSound from '../assets/audio/mech-engine.ogg';
import MechWalkSound from '../assets/audio/mech-walk.mp3';
import { WalkingEnums, WalkingGameObject } from './walking-game-object';
import { CameraHandleEnums, CameraHandleGameObject } from './camera-handle-game-object';
import { PlayerStatusService } from '../services/player-status-service';

const colorPalette = [
  0xff0000,
  0x00ffff,
  0xffff00,
  0xffffff,
  0x111111
];

export class CharacterGameObject extends GameObjectClass {
  characterId = null;
  inputs = null;
  activeAnimations = [];
  animations = null;

  name = '';
  baseColor = 0xffffff;

  constructor({ characterId, inputs }) {
    super();

    this.characterId = characterId;
    this.inputs = inputs;
    this.name = `player-${Math.random()}`;
    this.baseColor = colorPalette[~~(Math.random() * colorPalette.length)];

    this.onCreate();
    this.spawnAtRandomLocation(10.0);
  }

  spawnAtRandomLocation(maxOffset = 10.0) {
    this.position.set(
      Math.random() * maxOffset * 2.0 - maxOffset,
      maxOffset,
      Math.random() * maxOffset * 2.0 - maxOffset,
    );
  }

  onCreate() {
    const isUserControlled = this.characterId === NetworkService.clientId || this.characterId === null;

    const overrideMaterials = {
      'Material.001': (material) => {
        if ([0x111111, 0xffffff].includes(this.baseColor)) {
          material.color = new Three.Color(0xffffff);
        } else {
          material.color = new Three.Color(0x0c266e);
        }
      },
      'Material.002': (material) => {
        material.color = new Three.Color(this.baseColor);
      },
      'Material.003': (material) => {
        material.color = new Three.Color(this.baseColor);
      },
      'Material.007': (material) => {
        material.color = new Three.Color(0x000000);
      }
    };
    
    Promise.all([
      AssetsService.getModel(MechModel),
      AssetsService.getModel(MechModelMaterials, { overrideMaterials }),
      AssetsService.getAudio(MechEngineSound),
      AssetsService.getAudio(MechWalkSound),
      AssetsService.getModel(GunModel),
    ]).then(([
      mechModel,
      mechmodelMaterials,
      mechEngineSound,
      mechWalkSound,

      gunModel
    ]) => {
      portMaterialsToMixamoMesh(mechModel, mechmodelMaterials);

      mechModel.traverse(child => {
        if (child.material) {
          child.material.side = Three.FrontSide;
          child.material.metalnessMap = null;
          child.material.metalness = 0.5;
        }

        child.frustumCulled = false;
      });

      this.createAnimations(mechModel);

      mechModel.scale.setScalar(0.01);
      mechModel.position.y -= 0.1;
      CameraService.ignoreCameraCollisions(mechModel);
      this.add(mechModel);

      new PhysicsWrapper(this, {
        physicsShape: 'box',
        physicsSize: 0.5,
        physicsCapsuleHeight: 1.0,
        physicsFriction: 0.1,
        physicsRestitution: 0.0,
        physicsWeight: 1000.0,
        physicsPreventRotation: true,
        physicsControlled: true
      });
      const body = PhysicsService.getBodyFromObject(this);

      body.userData.inputs = this.inputs;
      body.userData.clientId = this.characterId;

      const cameraHandle = new CameraHandleGameObject();
      cameraHandle.follow(this, new Three.Vector3(-1.4, 1.4, -0.2));

      if (isUserControlled) {
        CameraService.useThirdPersonCamera(cameraHandle, new Three.Vector3(0.0, 0.4, -3.0), true);

        AudioService.setAudioVolume(mechEngineSound, 0.1);
        AudioService.playAudio(1, mechEngineSound, true);

        AudioService.setAudioVolume(mechWalkSound, 0.0);
        AudioService.playAudio(2, mechWalkSound, true);
      }

      const walkingController = new WalkingGameObject({
        target: this,
        spine: this.getObjectByName('mixamorigSpine1')
      });

      InputService.registerListener(({ key, status }) => {
        if (key === 'e' || key === 'mouse2') {
          if (status) {
            walkingController.mode = WalkingEnums.modeAim;
            cameraHandle.mode = CameraHandleEnums.modeZoom;
          } else {
            walkingController.mode = WalkingEnums.modeDefault;
            cameraHandle.mode = CameraHandleEnums.modeDefault;
          }
        }
      });

      const weaponSocket = this.getObjectByName('rightHandSlot');
      gunModel.rotation.x += -Math.PI / 2.0;
      gunModel.rotation.z += -Math.PI / 2.0;
      gunModel.scale.setScalar(100.0);
      weaponSocket.add(gunModel);

      TimeService.registerFrameListener(() => {
        const isGrounded = PhysicsService.isGrounded(PhysicsService.getBodyFromObject(this));
        const {
          isMoving,
          isRunning,
          speed
        } = walkingController;
        const isAiming = walkingController.mode === WalkingEnums.modeAim ? 1.0 : 0.0;

        let finalFocus = 0.0;

        if (isAiming) {
          finalFocus += 1.0;
        }

        if (isMoving) {
          finalFocus -= isMoving * 0.2;
        }

        if (isRunning) {
          finalFocus -= isRunning * 0.5;
        }

        if (!isGrounded) {
          finalFocus -= 0.25;
        }

        PlayerStatusService.focus = MathUtils.lerp(PlayerStatusService.focus, Math.max(0.0, finalFocus), 0.25);

        if (this.animations) {
          this.animations.blendInAnimation('idle-legs',  (Number(isGrounded) * 1.0 - isMoving) * 0.5, 0.25);
          this.animations.blendInAnimation('idle-body',  Math.max(0.0, Number(isGrounded) * 1.0 - isAiming - isMoving), 0.25);
          this.animations.blendInAnimation('aim-1', isAiming ? isAiming + isMoving * 100.0 + Number(!isGrounded) * 100.0 : 0.0, 0.25);
          this.animations.blendInAnimation('walk', Number(isGrounded) * Math.abs(1.0 - isRunning) * isMoving, 0.25);
          this.animations.setTimeScale('walk', Math.sign(speed));
          this.animations.blendInAnimation('run', Number(isGrounded) * isRunning * (isMoving * 0.85), 0.25);
          
          if (isGrounded) {
            this.animations.blendInAnimation('jump', 0.0, 0.1);
          } else {
            this.animations.blendInAnimation('jump', 1.0, 0.1);
          }
        }

        if (isUserControlled) {
          AudioService.setAudioVolume(mechWalkSound, Number(isGrounded) * isMoving * 0.5);
          AudioService.setAudioPlaybackRate(mechWalkSound, isMoving * 1.175 + isRunning * 0.5);
        }
      });
    });
  }

  createAnimations(model) {
    this.animations = new AnimationWrapper(model);

    Promise.all([
      AssetsService.getMixamoAnimation(MechIdleAnimation),
      AssetsService.getMixamoAnimation(MechRunningAnimation),
      AssetsService.getMixamoAnimation(MechWalkingAnimation),
      AssetsService.getMixamoAnimation(MechJumpAnimation),
      AssetsService.getMixamoAnimation(MechAimAnimation),
    ]).then(([
      mechIdleAnimation,
      mechRunningAnimation,
      mechWalkingAnimation,
      mechJumpAnimation,
      mechAimAnimation
    ]) => {
      this.animations.addMixamoAnimation('idle-legs', mechIdleAnimation,
        {
          filterBones: [
            'mixamorigRightUpLeg',
            'mixamorigRightLeg',
            'mixamorigRightFoot',
            'mixamorigRightToeBase',
            'mixamorigRightToe_End',

            'mixamorigLeftUpLeg',
            'mixamorigLeftLeg',
            'mixamorigLeftFoot',
            'mixamorigLeftToeBase',
            'mixamorigLeftToe_End',

            'mixamorigHips'
          ]
        }
      );
      this.animations.addMixamoAnimation('idle-body', mechIdleAnimation,
        {
          excludeBones: [
            'mixamorigRightUpLeg',
            'mixamorigRightLeg',
            'mixamorigRightFoot',
            'mixamorigRightToeBase',
            'mixamorigRightToe_End',

            'mixamorigLeftUpLeg',
            'mixamorigLeftLeg',
            'mixamorigLeftFoot',
            'mixamorigLeftToeBase',
            'mixamorigLeftToe_End',

            'mixamorigHips'
          ]
        }
      );
      this.animations.addMixamoAnimation('run', mechRunningAnimation);
      this.animations.addMixamoAnimation(
        'walk',
        mechWalkingAnimation
      );
      this.animations.addMixamoAnimation(
        'jump',
        mechJumpAnimation
      );
      this.animations.addMixamoAnimation(
        'aim-1',
        mechAimAnimation,
        {
          excludeBones: [
            'mixamorigRightUpLeg',
            'mixamorigRightLeg',
            'mixamorigRightFoot',
            'mixamorigRightToeBase',
            'mixamorigRightToe_End',

            'mixamorigLeftUpLeg',
            'mixamorigLeftLeg',
            'mixamorigLeftFoot',
            'mixamorigLeftToeBase',
            'mixamorigLeftToe_End',

            'mixamorigHips'
          ]
        }
      );
    });
  }
}