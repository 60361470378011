
class PlayerStatusServiceClass {
  focus = 0.0;
  
  health = 100.0;
  maxHealth = 100.0;

  energy = 100.0;
  maxEnergy = 100.0;
}

export const PlayerStatusService = new PlayerStatusServiceClass();