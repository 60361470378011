import { CameraMovementTypeEnums, CameraService, GameObjectClass, MathUtils, RenderService, Three, TimeService } from "three-default-cube";

export const CameraHandleEnums = {
  modeDefault: 'default',
  modeZoom: 'zoom',
};

export class CameraHandleGameObject extends GameObjectClass {
  mode = CameraHandleEnums.modeDefault;
  offset = new Three.Vector3(0.0, 0.0, 0.0);
  cameraDistance = 10.0;

  zoom = 0.0;

  constructor() {
    super();

    if (!CameraService.cameraControls) {
      return;
    }

    CameraService.tween = 1.0;

    setTimeout(() => {
      CameraService.cameraControls.minDistance = this.cameraDistance;
      CameraService.cameraControls.maxDistance = this.cameraDistance;
      CameraService.cameraControls.dollyTo(-this.cameraDistance, true);
    }, 100);

    this.createHandle();
  }

  createHandle() {
    TimeService.registerFrameListener(() => {
      this.position.copy(this.offset);
      CameraService.cameraControls.zoomTo(1.0 + this.zoom, true);

      if (this.mode === CameraHandleEnums.modeDefault) {
        this.zoom = MathUtils.lerp(this.zoom, 0.0, 0.2);
      } else if (this.mode === CameraHandleEnums.modeZoom) {
        this.zoom = MathUtils.lerp(this.zoom, 0.1, 0.2);
      }
    });
  }

  follow(target, offset) {
    this.offset = offset || new Three.Vector3();
    this.position.copy(this.offset);

    target.add(this);
  }
}
